import React from "react";

import { Grid, Button, Popup, Image as ReactImage, SemanticCOLORS } from "semantic-ui-react";
import { Link } from "react-router-dom";

import { Image as ImageModel, ImageState } from "../../../models/Image";
import { ModelType } from "../../../models/ModelType";
import {
    Definition,
    deserialiseInstances,
    objectColor,
    objectImage,
    objectDescription,
    Instance,
    ObjectDetectionAnswer,
    ObjectDetectionDescription,
} from "../../../models/model_types/object_detection";
import { NewInstance } from "./NewInstance";
import { EditInstances } from "./EditInstances";
import { AnnotationInfo, pdfJSX } from "../../helpers/AnnotationInfo";

import "./../Annotations.css";
import { ConfirmationPopUp } from "../../helpers/ConfirmationPopUp";

export enum Mode {
    NewInstance,
    Edit,
}

interface Props {
    image: ImageModel;
    modelType: ModelType;
    taskId?: string;
    verification: boolean;
    onSubmit: (
        discard: boolean,
        answer: any,
        imageState: ImageState,
        isAnnotationUpdate: boolean,
        isCrossValidation: boolean
    ) => void;
    onVerify: (approved: boolean, comment: string, discarded: boolean) => void;
}

class State {
    answer: ObjectDetectionAnswer = { instances: [] };
    mode: Mode = Mode.Edit;
    description: ObjectDetectionDescription = { label: "", shape_type: "" };
}

export class Annotation extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const { image } = this.props;
        let answer: ObjectDetectionAnswer = { instances: [] };

        if (image.annotations.length > 0) {
            const definition = props.modelType.definition as Definition;
            const instances = image.annotations[0].answerData.instances as Instance[];

            const [deserialisedInstances] = deserialiseInstances(definition.objects, instances);
            answer.instances = deserialisedInstances as Instance[];
            answer.instances = this.removeOutOfCropDetections(answer.instances);
        }

        this.state = {
            answer,
            mode: Mode.Edit,
            description: { label: "", shape_type: "" },
        };

        this.updatedInstances = this.updatedInstances.bind(this);
        this.enableNew = this.enableNew.bind(this);
    }

    componentDidUpdate(previousProps: Props) {
        if (previousProps !== this.props) {
            const { image } = this.props;
            let answer: ObjectDetectionAnswer = { instances: [] };

            if (image.annotations.length > 0) {
                const definition = this.props.modelType.definition as Definition;
                const instances = image.annotations[0].answerData.instances;

                const [deserialisedInstances] = deserialiseInstances(definition.objects, instances);
                answer.instances = deserialisedInstances as Instance[];
            }

            this.setState({ answer });
        }
    }

    updatedInstances(instances: Instance[], mode: Mode) {
        this.setState({ answer: { instances: instances }, mode });
    }

    enableNew(description: ObjectDetectionDescription) {
        this.setState({ mode: Mode.NewInstance, description: description });
    }

    removeOutOfCropDetections(instances: Instance[]) {
        /*Note (lachlan thorpe) - this function is to deal with a bug that saw 
        us introduce images with lots of detections outside of the crop */
        let filteredInstances = instances.filter((instance) => {
            let area = instance.area();
            if (area !== undefined) {
                return area > 0;
            }
            return true;
        });
        return filteredInstances;
    }

    render() {
        const definition = this.props.modelType.definition as Definition;
        const { image, modelType } = this.props;
        const { answer, mode, description } = this.state;
        const verification = this.props.verification;
        const ann = image.annotations[image.annotations.length - 1];

        let wizardJSX = <></>;
        if (mode === Mode.Edit) {
            wizardJSX = (
                <EditInstances
                    updatedInstances={this.updatedInstances}
                    instances={answer.instances}
                    image={image}
                    objects={definition.objects}
                />
            );
        } else if (mode === Mode.NewInstance) {
            wizardJSX = (
                <NewInstance
                    updatedInstances={this.updatedInstances}
                    definition={description}
                    instances={answer.instances}
                    image={image}
                    objects={definition.objects}
                />
            );
        }

        let redirectPath = verification
            ? `/model/${modelType.name}/verification`
            : `/model/${modelType.name}/annotation`;
        if (this.props.taskId != null) {
            redirectPath = verification
                ? `/model/${modelType.name}/verification/task/${this.props.taskId}`
                : `/model/${modelType.name}/annotation/task/${this.props.taskId}`;
        }

        let buttonsJSX = (
            <Link to={redirectPath}>
                <Button
                    color="green"
                    disabled={mode !== Mode.Edit}
                    onClick={() =>
                        this.props.onSubmit(
                            false,
                            this.state.answer,
                            ImageState.NeedsVerification,
                            false,
                            false
                        )
                    }
                    content={`Submit Annotation`}
                />
                <ConfirmationPopUp
                    trigger={
                        <Button
                            disabled={mode === Mode.NewInstance}
                            content="Discard image"
                            labelPosition="left"
                            icon="trash"
                            color="red"
                        />
                    }
                    description={"This will permanently discard the image"}
                    onYes={() => {
                        this.props.onSubmit(
                            true,
                            this.state.answer,
                            ImageState.NeedsVerification,
                            false,
                            false
                        );
                    }}
                    onNo={() => {}}
                />
            </Link>
        );

        if (verification) {
            buttonsJSX = (
                <Link to={redirectPath}>
                    <AnnotationInfo
                        approveButton={true}
                        ann={ann}
                        answer={answer}
                        onVerify={this.props.onVerify}
                        onSubmit={this.props.onSubmit}
                    />
                </Link>
            );
        }

        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={10}>
                        <div className="button-container">
                            {definition.objects.map((object) => (
                                <Popup
                                    trigger={
                                        <Button
                                            color={objectColor(object) as SemanticCOLORS}
                                            disabled={mode === Mode.NewInstance}
                                            onClick={this.enableNew.bind(this, object)}
                                            content={`New ${object.label}`}
                                        />
                                    }
                                    header={objectDescription(object)}
                                    content={<ReactImage src={objectImage(object)} size="small" />}
                                    on="hover"
                                    key={object.label}
                                />
                            ))}
                        </div>
                    </Grid.Column>
                    <Grid.Column width={5} floated="right">
                        {pdfJSX(modelType)}
                    </Grid.Column>
                </Grid.Row>
                {wizardJSX}
                <Grid.Column width={10}>
                    <Grid.Row>{buttonsJSX}</Grid.Row>
                </Grid.Column>
            </Grid>
        );
    }
}
