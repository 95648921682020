import React from "react";
import {
    Grid,
    Header,
    Input,
    TextArea,
    Table,
    Button,
    Form,
    Container,
    Progress,
    SemanticCOLORS,
    Icon,
    Dropdown,
    Modal,
    Popup,
    Accordion,
    Loader,
} from "semantic-ui-react";
import { ProvidedAppStore } from "../../store/AppStore";
import { Task, TaskState, TaskPriority, Project } from "../../models/Task";
import { ModelType } from "../../models/ModelType";
import { RouteComponentProps } from "react-router-dom";
import { inject, observer } from "mobx-react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { ImageState, ImageStateUse } from "../../models/Image";
import { ConfirmationPopUp } from "../helpers/ConfirmationPopUp";
import { AbortRequests } from "../helpers/AbortRequests";

interface TopUpProps {
    task: Task;
    onPress: (task: Task, sourceState: ImageState) => void;
}

interface TopUpState {
    selectedState: ImageState;
}

class TopUpCell extends React.Component<TopUpProps, TopUpState> {
    constructor(props: TopUpProps) {
        super(props);

        this.state = { selectedState: ImageState.Incoming };
    }

    render() {
        const { task, onPress } = this.props;
        const { selectedState } = this.state;

        const topUpFromJSX = (
            <Dropdown
                options={Object.keys(ImageState).map((item: string) => {
                    return { key: item, text: item, value: item };
                })}
                defaultValue={ImageState.Incoming}
                onChange={(e: any, props: any) => {
                    this.setState({ selectedState: props.value });
                }}
            ></Dropdown>
        );

        let topUpJSX = (
            <Button fluid color="teal">
                Top Up Data From
            </Button>
        );

        const numImagesEstimated = Math.max(
            0,
            task.target - task.verified - task.needsVerification - task.needsAnnotation
        );

        let confirmJSX = (
            <ConfirmationPopUp
                trigger={topUpJSX}
                description={`This will attempt to move ${numImagesEstimated} images of sports: ${task.sports.join(
                    " "
                )}, stadiums: ${task.stadiums.join(" ")} from ${selectedState} to NeedsAnnotation`}
                onYes={() => {
                    onPress(task, selectedState);
                }}
                onNo={() => {}}
            />
        );

        return (
            <Table.Cell>
                {confirmJSX}
                {topUpFromJSX}
            </Table.Cell>
        );
    }
}

type Props = RouteComponentProps & ProvidedAppStore;

interface State {
    target: number;
    description: string;
    instructions: string;
    model: string;
    deadline: Date;
    sports: string[];
    stadiums: string[];
    applications: string[];
    taskProjects: string[];
    currentTasks: Task[];
    priority: TaskPriority;
    enabled: boolean;
    tags: string[];
    open: boolean;
    id: number;
    projects: Project[];
    crossValidationPercentage: number;
    earliestUploadDate?: Date;
    latestUploadDate?: Date;
    tasksLoaded?: boolean;
}

@inject("store")
@observer
export class Tasks extends React.Component<Props, State> implements AbortRequests {
    controller: AbortController = new AbortController();
    mounted: boolean = false;

    defaultState = {
        model: "VolleyballVideo",
        currentTasks: [],
        target: 500,
        deadline: new Date(),
        sports: [],
        stadiums: [],
        applications: [],
        description: "",
        instructions: "",
        taskProjects: [],
        priority: TaskPriority.Low,
        enabled: true,
        tags: [],
        open: false,
        id: 0,
        projects: [],
        crossValidationPercentage: 5,
        earliestUploadDate: undefined,
        latestUploadDate: undefined,
        tasksLoaded: false,
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            ...this.defaultState,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.fetchTasks = this.fetchTasks.bind(this);
        this.fetchProjects = this.fetchProjects.bind(this);
        this.setType = this.setType.bind(this);
        this.changeState = this.changeState.bind(this);
        this.submitTask = this.submitTask.bind(this);
        this.handleGeneralChange = this.handleGeneralChange.bind(this);
        this.moveImages = this.moveImages.bind(this);
        this.editTask = this.editTask.bind(this);
    }

    async setType() {
        await this.props.store!.modelStore.setModelType(this.state.model);
        await this.props.store!.modelStore.fetchStatistics(ImageStateUse.Regular);
    }

    async componentDidMount() {
        this.mounted = true;
        await this.setType();
        this.fetchTasks(true);
        this.fetchProjects();
    }

    async componentWillUnmount() {
        this.mounted = false;
        this.controller.abort();
    }

    async submitTask() {
        const response = await this.props.store!.hwkflowClient.submitTask(
            this.state.description,
            this.state.instructions,
            this.state.model,
            this.state.target,
            this.state.deadline,
            this.state.sports,
            this.state.stadiums,
            this.state.applications,
            this.state.priority,
            this.state.tags,
            this.state.taskProjects,
            this.state.crossValidationPercentage,
            this.state.earliestUploadDate,
            this.state.latestUploadDate
        );
        if (response.status !== 200) {
            alert(`task submit failed! ${response.status} - ${response.statusText}`);
        }

        if (this.mounted) this.fetchTasks(false);
    }

    fetchTasks(updateState: boolean) {
        this.props
            .store!.hwkflowClient.fetchTasks(updateState, this.controller.signal)
            .then((response) => {
                this.setState({ currentTasks: response.data as Task[] });
                this.setState({ tasksLoaded: true });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    fetchProjects() {
        this.props
            .store!.hwkflowClient.fetchProjects(this.controller.signal)
            .then((response) => {
                this.setState({ projects: response.data as Project[] });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    moveImages(task: Task, sourceState: ImageState) {
        this.props.store!.hwkflowClient.topUpTask(task.id, sourceState);
    }

    async changeState(state: any, id: number) {
        await this.props.store!.hwkflowClient.setTaskState(id, state);
        if (this.mounted) this.fetchTasks(false);
    }

    async editTask(id: number) {
        await this.props.store!.hwkflowClient.editTask(
            id,
            this.state.description,
            this.state.instructions,
            this.state.model,
            Number(this.state.target),
            this.state.deadline,
            this.state.sports,
            this.state.stadiums,
            this.state.applications,
            this.state.priority,
            this.state.tags,
            this.state.taskProjects,
            Number(this.state.crossValidationPercentage),
            this.state.earliestUploadDate,
            this.state.latestUploadDate
        );
        if (this.mounted) this.fetchTasks(false);
    }

    handleNumberEntry = (e: any) => {
        const re = /^[0-9\b]+$/;
        //Check that entry is a number
        if (e.target.value !== "" && re.test(e.target.value)) {
            this.setState({ target: e.target.value, enabled: true });
        } else {
            this.setState({ enabled: false });
        }
    };

    handlePctEntry = (e: any) => {
        const re = /^[0-9\b]+$/;
        //Check that entry is a number
        if (e.target.value !== "" && re.test(e.target.value)) {
            this.setState({ crossValidationPercentage: e.target.value, enabled: true });
        } else {
            this.setState({ enabled: false });
        }
    };

    handleSubmit = () => {
        this.submitTask();
    };
    handleEdit = () => {
        this.editTask(this.state.id);
    };

    handleGeneralChange = (event: any, data: any) => {
        const { name, value } = data;
        this.setState({ [name]: value } as Pick<State, keyof State>);

        if (name === "sports") {
            this.props.store!.modelStore.fetchTags(value);
        }
    };

    handleModelSelection = (choice: any) => {
        this.setState(
            {
                model: choice,
            },
            () => {
                this.setType();
            }
        );
    };

    handleEarliestUploadDateChanged = (value: any) => {
        // note(alex.shaw): We set the hours in-place as we dont want to store the return value in the state
        value.setHours(0, 0, 0, 0);
        this.setState({ earliestUploadDate: value });
    };

    handleLatestUploadDateChanged = (value: any) => {
        // note(alex.shaw): We set the hours in-place as we dont want to store the return value in the state
        value.setHours(23, 59, 59, 999);
        this.setState({ latestUploadDate: value });
    };

    fnInputSelection = (name: string, values: string[], choices: string[]) => {
        let allChoices = [];
        allChoices.push(...choices);
        const options = allChoices.map((choice: string) => {
            return { text: choice, value: choice };
        });

        return (
            <Form.Field>
                <Dropdown
                    clearable
                    multiple
                    search
                    selection
                    name={name}
                    options={options}
                    placeholder={name}
                    onChange={this.handleGeneralChange}
                    defaultValue={values}
                />
            </Form.Field>
        );
    };

    generateDropdown = (entries: any) => {
        const dropDown = entries.map((entry: string) => {
            return {
                key: entry,
                text: entry,
                value: entry,
            };
        });
        return dropDown;
    };

    percentKeys: { [state: string]: any } = {
        Incoming: { number: 0, colour: "grey" },
        InProgress: { number: 33, colour: "yellow" },
        AllAnnotated: { number: 66, colour: "orange" },
        Complete: { number: 100, colour: "green" },
        OnHold: { number: 50, colour: "red" },
        NoData: { number: 0, colour: "red" },
        Training: { number: 100, colour: "green" },
    };

    fnGenerateForm = (onSubmit: any) => {
        const modelTypesStore = this.props.store!.modelTypesStore;
        const modelTypes = modelTypesStore.modelTypes;
        const modelsDropDown = this.generateDropdown(
            modelTypes.map((type: ModelType) => type.name)
        );
        const priorityDropDown = this.generateDropdown(Object.keys(TaskPriority));
        let formJSX = (
            <Container>
                <Grid>
                    <Form onSubmit={onSubmit}>
                        <Grid.Row>
                            <Form.Group>
                                <Grid.Column className="margins">
                                    <b>Model</b>
                                    <Form.Dropdown
                                        selection
                                        options={modelsDropDown}
                                        placeholder={"Model"}
                                        value={this.state.model}
                                        onChange={(_e, props) => {
                                            this.handleModelSelection(props.value);
                                        }}
                                    />
                                </Grid.Column>
                                <Grid.Column className="margins">
                                    <b>Sports</b>
                                    {this.fnInputSelection(
                                        "sports",
                                        this.state.sports,
                                        this.props.store!.modelStore.sports
                                    )}
                                </Grid.Column>
                                <Grid.Column className="margins">
                                    <b>Stadiums</b>
                                    {this.fnInputSelection(
                                        "stadiums",
                                        this.state.stadiums,
                                        this.props.store!.modelStore.stadiums
                                    )}
                                </Grid.Column>
                                <Grid.Column className="margins">
                                    <b>Deadline</b>
                                    <br />
                                    <DayPickerInput
                                        placeholder={"Deadline"}
                                        onDayChange={(value: any) => {
                                            this.setState({ deadline: value });
                                        }}
                                        value={this.state.deadline}
                                    />
                                </Grid.Column>
                                <Grid.Column className="margins">
                                    <b>Priority</b>
                                    <Form.Dropdown
                                        selection
                                        options={priorityDropDown}
                                        name={"priority"}
                                        placeholder={"Priority"}
                                        onChange={this.handleGeneralChange}
                                        value={this.state.priority}
                                    />
                                </Grid.Column>
                            </Form.Group>
                        </Grid.Row>
                        <Grid.Row>
                            <Form.Group>
                                <Grid.Column className="margins">
                                    <b>Annotation Target</b>
                                    <Form.Field
                                        control={Input}
                                        onChange={this.handleNumberEntry}
                                        placeholder="Annotation Target"
                                        defaultValue={this.state.target}
                                    />
                                </Grid.Column>
                                <Grid.Column className="margins">
                                    <b>Cross Validation Percentage</b>
                                    <Form.Field
                                        control={Input}
                                        onChange={this.handlePctEntry}
                                        placeholder="Cross Validation Percentage"
                                        defaultValue={this.state.crossValidationPercentage}
                                    />
                                </Grid.Column>
                                <Grid.Column className="margins">
                                    <b>Tags</b>
                                    {this.fnInputSelection(
                                        "tags",
                                        this.state.tags,
                                        this.props.store!.modelStore.tags
                                    )}
                                </Grid.Column>
                                <Grid.Column className="margins">
                                    <b>Task Projects</b>
                                    {this.fnInputSelection(
                                        "taskProjects",
                                        this.state.taskProjects,
                                        this.state.projects.map((proj) => proj.name)
                                    )}
                                </Grid.Column>
                                <Grid.Column className="margins">
                                    <b>Earliest Upload Date</b>
                                    <br />
                                    <DayPickerInput
                                        dayPickerProps={{
                                            disabledDays: {
                                                after:
                                                    this.state.latestUploadDate ||
                                                    new Date(8640000000000000), // Year 275760
                                            },
                                        }}
                                        placeholder={"Earliest Upload Date"}
                                        onDayChange={this.handleEarliestUploadDateChanged}
                                        value={this.state.earliestUploadDate}
                                    />
                                </Grid.Column>
                                <Grid.Column className="margins">
                                    <b>Latest Upload Date</b>
                                    <br />
                                    <DayPickerInput
                                        dayPickerProps={{
                                            disabledDays: {
                                                before:
                                                    this.state.earliestUploadDate ||
                                                    new Date(-8640000000000000), // Year -271821
                                            },
                                        }}
                                        placeholder={"Latest Upload Date"}
                                        onDayChange={this.handleLatestUploadDateChanged}
                                        value={this.state.latestUploadDate}
                                    />
                                </Grid.Column>
                            </Form.Group>
                        </Grid.Row>
                        <Grid.Row>
                            <Form.Group>
                                <Grid.Column className="margins">
                                    <b>Description</b>
                                    <Form.Field
                                        name={"description"}
                                        control={TextArea}
                                        placeholder="Description"
                                        value={this.state.description}
                                        onChange={this.handleGeneralChange}
                                    />
                                </Grid.Column>
                                <Grid.Column className="margins">
                                    <b>Instructions</b>
                                    <Form.Field
                                        name={"instructions"}
                                        control={TextArea}
                                        placeholder="Instructions"
                                        value={this.state.instructions}
                                        onChange={this.handleGeneralChange}
                                    />
                                </Grid.Column>
                                <Grid.Column className="center-height margins">
                                    <Form.Button
                                        content="Submit Task"
                                        disabled={!this.state.enabled}
                                    />
                                </Grid.Column>
                            </Form.Group>
                        </Grid.Row>
                    </Form>
                </Grid>
            </Container>
        );

        return formJSX;
    };

    render() {
        this.state.currentTasks.sort((a: any, b: any) => (a.id > b.id ? -1 : b.id > a.id ? 1 : 0));
        const taskList = this.state.currentTasks.map((task: Task) => {
            const createdAt = new Date(task.createdAt);
            const deadline = new Date(task.deadline);

            let percent = this.percentKeys[task.state]["number"];
            let colour = this.percentKeys[task.state]["colour"] as SemanticCOLORS;

            let projectList = "";
            if (task.projects) {
                projectList = task.projects.map((proj) => proj.name).join(" ");
            }

            let incomingJSX = <div />;
            if (task.state === TaskState.Incoming) {
                incomingJSX = (
                    <Button
                        fluid
                        onClick={() => this.changeState(TaskState.InProgress, task.id)}
                        color="green"
                    >
                        Start
                    </Button>
                );
            }
            let inProgressJSX = <div />;
            if (task.state === TaskState.InProgress || task.state === TaskState.AllAnnotated) {
                inProgressJSX = (
                    <Button
                        fluid
                        onClick={() => this.changeState(TaskState.OnHold, task.id)}
                        color="green"
                    >
                        Pause
                    </Button>
                );
            }
            let onHoldJSX = <div />;
            if (task.state === TaskState.OnHold) {
                let newState = TaskState.InProgress;
                if (task.annotated >= task.target) {
                    newState = TaskState.AllAnnotated;
                }
                onHoldJSX = (
                    <Button fluid onClick={() => this.changeState(newState, task.id)} color="green">
                        Resume
                    </Button>
                );
            }
            let completeJSX = <div />;
            if (task.state !== TaskState.Complete && task.state !== TaskState.Training) {
                let newState = TaskState.Complete;
                completeJSX = (
                    <ConfirmationPopUp
                        trigger={
                            <Button fluid color="green">
                                Complete
                            </Button>
                        }
                        description={"This will permanently complete the task"}
                        onYes={() => {
                            this.changeState(newState, task.id);
                        }}
                        onNo={() => {}}
                    />
                );
            }

            let priorityJSX = <Icon name="angle double down" color="green" />;
            if (task.priority === TaskPriority.Medium) {
                priorityJSX = <Icon name="angle up" color="orange" />;
            }
            if (task.priority === TaskPriority.High) {
                priorityJSX = <Icon name="angle double up" color="red" />;
            }

            let editJSX = (
                <Modal
                    onClose={() => this.setState({ open: false })}
                    onOpen={() =>
                        this.setState(
                            {
                                open: true,
                                id: task.id,
                                model: task.model.name,
                                sports: task.sports,
                                stadiums: task.stadiums,
                                target: task.target,
                                deadline: task.deadline,
                                priority: task.priority,
                                tags: task.failureTags,
                                description: task.description,
                                instructions: task.instructions,
                                // note(alex.shaw): This is a bit strange, but we need to create a new date
                                // object from the existing one to prevent an issue with DayPickerInput.
                                earliestUploadDate: task.earliestUploadDate
                                    ? new Date(task.earliestUploadDate)
                                    : undefined,
                                latestUploadDate: task.latestUploadDate
                                    ? new Date(task.latestUploadDate)
                                    : undefined,
                            },
                            () => {
                                this.setType();
                            }
                        )
                    }
                    open={this.state.open}
                    trigger={
                        <Button>
                            <Icon name="pencil" />
                        </Button>
                    }
                >
                    <Modal.Header>Edit Task</Modal.Header>
                    <Modal.Content>{this.fnGenerateForm(this.handleEdit)}</Modal.Content>
                    <Modal.Actions>
                        <Button
                            color="black"
                            onClick={() =>
                                this.setState({
                                    ...this.defaultState,
                                    open: false,
                                    currentTasks: this.state.currentTasks,
                                })
                            }
                        >
                            Exit
                        </Button>
                    </Modal.Actions>
                </Modal>
            );

            let popUpJSX = (
                <Table celled padded>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Sport</Table.HeaderCell>
                            <Table.HeaderCell>Stadium</Table.HeaderCell>
                            <Table.HeaderCell>Tags</Table.HeaderCell>
                            <Table.HeaderCell>Instructions</Table.HeaderCell>
                            <Table.HeaderCell>Created</Table.HeaderCell>
                            <Table.HeaderCell>Annotation Data</Table.HeaderCell>
                            <Table.HeaderCell>Verification Data</Table.HeaderCell>
                            <Table.HeaderCell>
                                Discarded/ Annotated/ Verified/ Target
                            </Table.HeaderCell>
                            <Table.HeaderCell>Deadline</Table.HeaderCell>
                            <Table.HeaderCell>Train On Completion</Table.HeaderCell>
                            {task.earliestUploadDate && (
                                <Table.HeaderCell>Earliest Image Upload Date</Table.HeaderCell>
                            )}
                            {task.latestUploadDate && (
                                <Table.HeaderCell>Latest Image Upload Date</Table.HeaderCell>
                            )}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row key={task.id}>
                            <Table.Cell>{task.sports.join(" ")}</Table.Cell>
                            <Table.Cell>{task.stadiums.join(" ")}</Table.Cell>
                            <Table.Cell>{task.failureTags.join(" ")}</Table.Cell>
                            <Table.Cell>{task.instructions}</Table.Cell>
                            <Table.Cell>{createdAt.toDateString()}</Table.Cell>
                            <Table.Cell>{task.needsAnnotation}</Table.Cell>
                            <Table.Cell>{task.needsVerification}</Table.Cell>
                            <Table.Cell>
                                {String(task.discarded) +
                                    "/" +
                                    String(task.annotated) +
                                    "/" +
                                    String(task.verified) +
                                    "/" +
                                    String(task.target)}
                            </Table.Cell>
                            <Table.Cell>{deadline.toDateString()}</Table.Cell>
                            <Table.Cell>{projectList}</Table.Cell>
                            {task.earliestUploadDate && (
                                <Table.Cell>{task.earliestUploadDate}</Table.Cell>
                            )}
                            {task.latestUploadDate && (
                                <Table.Cell>{task.latestUploadDate}</Table.Cell>
                            )}
                        </Table.Row>
                    </Table.Body>
                </Table>
            );

            const topUpCellJSX = <TopUpCell task={task} onPress={this.moveImages} />;

            return (
                <Table.Row key={task.id}>
                    <Table.Cell>
                        <Popup content={popUpJSX} trigger={priorityJSX} />
                    </Table.Cell>
                    <Table.Cell>{task.model.name}</Table.Cell>
                    <Table.Cell width={4}>{task.description}</Table.Cell>
                    <Table.Cell width={6}>
                        <Progress
                            percent={percent}
                            autoSuccess
                            color={colour}
                            width={6}
                            label={task.state}
                        />
                    </Table.Cell>
                    {topUpCellJSX}
                    <Table.Cell>
                        {incomingJSX}
                        {onHoldJSX}
                        {inProgressJSX}
                        {completeJSX}
                        <ConfirmationPopUp
                            trigger={
                                <Button fluid color="red">
                                    Archive
                                </Button>
                            }
                            description={"This will permanently archive the task"}
                            onYes={() => {
                                this.changeState(TaskState.Archived, task.id);
                            }}
                            onNo={() => {}}
                        />
                    </Table.Cell>
                    <Table.Cell>{editJSX}</Table.Cell>
                </Table.Row>
            );
        });

        let taskDisplayJSX = (
            <Table celled padded>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell>Model</Table.HeaderCell>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>{taskList}</Table.Body>
            </Table>
        );

        return (
            <Container>
                <Header as="h2">Create Task</Header>
                {this.fnGenerateForm(this.handleSubmit)}
                <br />
                <ManageTagsAndProjects setType={this.setType} />
                {this.state.tasksLoaded ? taskDisplayJSX : <Loader active />}
            </Container>
        );
    }
}

interface TagsState {
    newTagName: string;
    deleteTagName: string;
    newProjectName: string;
    newProjectId: string;
    open: boolean;
}

interface TagsProps {
    setType: () => void;
}

type TProps = TagsProps & ProvidedAppStore;

@inject("store")
export class ManageTagsAndProjects
    extends React.Component<TProps, TagsState>
    implements AbortRequests
{
    controller: AbortController = new AbortController();
    mounted: boolean = false;
    modelsDropdown = [{ key: "", text: "", value: "" }];

    constructor(props: TProps) {
        super(props);

        this.state = {
            newTagName: "",
            deleteTagName: "",
            newProjectName: "",
            newProjectId: "",
            open: false,
        };
        this.addTag = this.addTag.bind(this);
        this.removeTag = this.removeTag.bind(this);
        this.handleGeneralChange = this.handleGeneralChange.bind(this);
        this.addProject = this.addProject.bind(this);
    }

    async componentDidMount() {
        this.mounted = true;
    }

    async componentWillUnmount() {
        this.mounted = false;
    }

    async addTag() {
        await this.props.store!.hwkflowClient.addTag(this.state.newTagName);
        if (this.mounted) {
            this.setState({ ...this.state, newTagName: "" });
        }
    }
    async removeTag() {
        await this.props.store!.hwkflowClient.removeTag(this.state.deleteTagName);
        if (this.mounted) {
            this.setState({ ...this.state, deleteTagName: "" });
        }
    }

    async addProject() {
        if (this.state.newProjectName === "" || this.state.newProjectId === "") return;
        console.log(this.state.newProjectName);
        this.props.store!.hwkflowClient.addProject(
            this.state.newProjectName,
            this.state.newProjectId
        );
        this.setState({ newProjectName: "", newProjectId: "" });
        this.props.setType();
    }

    handleGeneralChange = (event: any, data: any) => {
        const { name, value } = data;
        this.setState({ [name]: value } as Pick<TagsState, keyof TagsState>);
    };

    render() {
        return (
            <Container>
                <Accordion styled={true} fluid={true}>
                    <Accordion.Title
                        active={this.state.open}
                        index={0}
                        onClick={() => {
                            this.setState({ ...this.state, open: !this.state.open });
                        }}
                    >
                        <Icon name="dropdown" />
                        Manage Tags and Projects
                    </Accordion.Title>
                    <Accordion.Content active={this.state.open}>
                        <Grid>
                            <Grid.Row>
                                <Form onSubmit={this.addTag} className="margins">
                                    <Form.Group>
                                        <Grid.Column className="margins">
                                            <b>New Tag Name</b>
                                            <Form.Field
                                                name={"newTagName"}
                                                control={Input}
                                                placeholder=""
                                                value={this.state.newTagName}
                                                onChange={this.handleGeneralChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column className="center-height margins">
                                            <Form.Button
                                                content="Add Tag"
                                                disabled={this.state.newTagName === ""}
                                            />
                                        </Grid.Column>
                                    </Form.Group>
                                </Form>
                                <Form onSubmit={this.removeTag} className="margins">
                                    <Form.Group>
                                        <Grid.Column className="margins">
                                            <b>Delete Tag Name</b>
                                            <Form.Field
                                                name={"deleteTagName"}
                                                control={Input}
                                                placeholder=""
                                                value={this.state.deleteTagName}
                                                onChange={this.handleGeneralChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column className="center-height margins">
                                            <Form.Button
                                                content="Delete Tag"
                                                disabled={this.state.deleteTagName === ""}
                                            />
                                        </Grid.Column>
                                    </Form.Group>
                                </Form>
                            </Grid.Row>
                            <Grid.Row>
                                <Form onSubmit={this.addProject} className="margins">
                                    <Form.Group>
                                        <Grid.Column className="margins">
                                            <b>New Project Name</b>
                                            <Form.Field
                                                name={"newProjectName"}
                                                control={Input}
                                                placeholder=""
                                                value={this.state.newProjectName}
                                                onChange={this.handleGeneralChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column className="margins">
                                            <b>New Project ID</b>
                                            <Form.Field
                                                name={"newProjectId"}
                                                control={Input}
                                                placeholder=""
                                                value={this.state.newProjectId}
                                                onChange={this.handleGeneralChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column className="center-height margins">
                                            <Form.Button
                                                content="Add Project"
                                                disabled={
                                                    !(
                                                        this.state.newProjectName !== "" &&
                                                        this.state.newProjectId !== ""
                                                    )
                                                }
                                            />
                                        </Grid.Column>
                                    </Form.Group>
                                </Form>
                            </Grid.Row>
                        </Grid>
                    </Accordion.Content>
                </Accordion>
            </Container>
        );
    }
}
