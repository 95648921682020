import React from "react";
import axios from "axios";
import ReactDOM from "react-dom";
import Keycloak from "keycloak-js";
import { v4 as uuidv4 } from "uuid";
import packageJson from "../package.json";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { REFRESH_TOKEN_KEY, TOKEN_KEY, SESSION_UUID, LAST_ACTIVITY_TIME } from "./constants/auth";
import { SESSION_LOGOUT_TIME } from "./components/helpers/SessionTimer";

const getSessionUuid = (url: string) => {
    const lastActivityTime = window.sessionStorage.getItem(LAST_ACTIVITY_TIME);
    const date = new Date().getTime();
    const timeDiffSession = Date.now() - (Number(lastActivityTime) || date);

    let sessionId = window.sessionStorage.getItem(SESSION_UUID);
    if (!sessionId) {
        sessionId = uuidv4();
        window.sessionStorage.setItem(SESSION_UUID, sessionId);
    }
    //  update LAST_ACTIVITY_TIME if it is not a polling query
    // get-active-use is re-requested every 30 sec
    if (!url.includes("metrics/get-active-user")) {
        window.sessionStorage.setItem(LAST_ACTIVITY_TIME, String(date));
    }
    // if user did nothing for more than 15 minutes we update session uuid and time
    else if (sessionId && timeDiffSession > SESSION_LOGOUT_TIME) {
        sessionId = uuidv4();
        window.sessionStorage.setItem(SESSION_UUID, sessionId);
    }

    return sessionId;
};

(async () => {
    const environment = (await axios.get("/environment")).data;
    const version = (await axios.get(`${environment.HWKFLOW_BASE_URL}/version`)).data?.version;
    const { KEYCLOAK_BASE_URL, KEYCLOAK_CLIENT, KEYCLOAK_REALM } = environment;

    const keycloak = new Keycloak({
        url: KEYCLOAK_BASE_URL,
        realm: KEYCLOAK_REALM,
        clientId: KEYCLOAK_CLIENT,
    });

    keycloak.onTokenExpired = () => {
        keycloak
            .updateToken(60) // Update the token when will last less than 1 minute
            .then(function (refreshed) {
                if (refreshed) {
                    console.log("Token was successfully refreshed");
                    window.localStorage.setItem(TOKEN_KEY, keycloak.token || "");
                    window.localStorage.setItem(REFRESH_TOKEN_KEY, keycloak.refreshToken || "");
                } else {
                    console.log("Token is still valid");
                }
            })
            .catch(function () {
                console.log("Failed to refresh the token, or the session has expired");
            });
    };

    try {
        const authenticated = await keycloak.init({
            onLoad: "login-required",
            silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
            checkLoginIframe: false,
            token: window.localStorage.getItem(TOKEN_KEY) || "",
            refreshToken: window.localStorage.getItem(REFRESH_TOKEN_KEY) || "",
        });

        if (authenticated) {
            window.localStorage.setItem(TOKEN_KEY, keycloak.token || "");
            window.localStorage.setItem(REFRESH_TOKEN_KEY, keycloak.refreshToken || "");

            // Request interceptors for API calls
            axios.interceptors.request.use(
                (config: any) => {
                    const session_uuid = getSessionUuid(config.url);
                    config.headers["Authorization"] = `Bearer ${keycloak.token}`;
                    config.headers["SessionId"] = session_uuid;

                    return config;
                },
                (error) => {
                    return Promise.reject(error);
                }
            );
        }
        console.log("auth", authenticated);
    } catch (err) {
        console.error("Failed to authenticate", err);
    }

    (window as any).keycloak = keycloak;

    // redirects to keycloak auth when 401 error
    axios.interceptors.response.use(
        (response: any) => response,
        (error) => {
            if (error.response?.status === 401) {
                window.localStorage.removeItem(TOKEN_KEY);
                window.localStorage.removeItem(REFRESH_TOKEN_KEY);
                keycloak.logout();
            }
            return Promise.reject(error);
        }
    );
    ReactDOM.render(
        <React.StrictMode>
            <App
                environment={environment}
                frontendVersion={packageJson.version}
                backendVersion={version}
            />
        </React.StrictMode>,
        document.getElementById("root")
    );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
