import React from "react";
import { Grid, Message, Radio, TextArea, Table, Button, Form, Container } from "semantic-ui-react";
import { ProvidedAppStore } from "../../store/AppStore";
import { Bug, ReportType } from "../../models/Bug";
import { AbortRequests } from "../helpers/AbortRequests";
import { inject, observer } from "mobx-react";
import { ConfirmationPopUp } from "../helpers/ConfirmationPopUp";

interface State {
    disabled: boolean;
    report: string;
    type: ReportType;
    currentReports: Bug[];
}

@inject("store")
@observer
export class BugReporting
    extends React.Component<ProvidedAppStore, State>
    implements AbortRequests
{
    controller: AbortController = new AbortController();
    mounted: boolean = false;

    constructor(props: ProvidedAppStore) {
        super(props);

        this.state = {
            report: "",
            disabled: true,
            type: ReportType.BugFix,
            currentReports: [],
        };
        this.handleReportChange = this.handleReportChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.fetchBugs = this.fetchBugs.bind(this);
    }

    async componentDidMount() {
        this.mounted = true;
        await this.fetchBugs();
    }

    async componentWillUnmount() {
        this.mounted = false;
        this.controller.abort();
    }

    async submitReport() {
        const response = await this.props.store!.hwkflowClient.submitReport(
            this.state.report,
            this.state.type
        );
        if (response.status !== 200) {
            alert(`bug submit failed! ${response.status} - ${response.statusText}`);
        }
        if (this.mounted) this.fetchBugs();
    }

    fetchBugs() {
        this.props
            .store!.hwkflowClient.fetchBugs({ report_type: "All" }, this.controller.signal)
            .then((response) => this.setState({ currentReports: response.data as Bug[] }))
            .catch(function (error) {
                console.log(error);
            });
    }

    async increment(id: number) {
        await this.props.store!.hwkflowClient.upvoteBug(id);
        if (this.mounted) this.fetchBugs();
    }
    async delete(id: number) {
        await this.props.store!.hwkflowClient.deleteBug(id);
        if (this.mounted) this.fetchBugs();
    }

    handleReportChange = (event: any) => {
        if (this.state.report !== "") {
            this.setState({ disabled: false });
        }
        if (this.state.report.length > 300) {
            this.setState({ disabled: true });
        }
        this.setState({ report: event.target.value });
    };

    handleTypeChange = (e: any, { value }: any) => {
        this.setState({ type: value });
    };

    handleSubmit = () => {
        this.submitReport();
        this.setState({ report: "", disabled: true });
    };

    render() {
        let definitionJSX = (
            <Message>
                Use this page to report any bugs or suggested improvements to user experience (300
                characters max). Please have a quick look at existing reports to see if someone has
                reported the same thing. In this case, use the upvote button to add 1 vote to this
                report
            </Message>
        );

        this.state.currentReports.sort((a: any, b: any) =>
            a.votes > b.votes ? -1 : b.votes > a.votes ? 1 : 0
        );
        const bugList = this.state.currentReports.map((bug: Bug) => {
            return (
                <Table.Row key={bug.id}>
                    <Table.Cell>
                        <Button onClick={() => this.increment(bug.id)} color="green">
                            Upvote
                        </Button>
                    </Table.Cell>
                    <Table.Cell>{bug.votes}</Table.Cell>
                    <Table.Cell>{bug.type}</Table.Cell>
                    <Table.Cell>{bug.report}</Table.Cell>
                    <Table.Cell>
                        <ConfirmationPopUp
                            trigger={<Button color="red">Delete</Button>}
                            description={"This will permanently delete this bug report"}
                            onYes={() => {
                                this.delete(bug.id);
                            }}
                            onNo={() => {}}
                        />
                    </Table.Cell>
                </Table.Row>
            );
        });

        let bugDisplayJSX = (
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell>Votes</Table.HeaderCell>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        <Table.HeaderCell>Report</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>{bugList}</Table.Body>
            </Table>
        );

        return (
            <Container>
                {definitionJSX}
                <Grid>
                    <Grid.Row>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Group>
                                <Form.Field
                                    control={TextArea}
                                    value={this.state.report}
                                    onChange={this.handleReportChange}
                                />
                                <Form.Button
                                    content="Submit Report"
                                    disabled={this.state.disabled}
                                />
                            </Form.Group>
                            <Form.Group inline>
                                <Form.Field>
                                    <Radio
                                        label="Bug"
                                        name="radioGroup"
                                        value={ReportType.BugFix}
                                        checked={this.state.type === ReportType.BugFix}
                                        onChange={this.handleTypeChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Radio
                                        label="Suggestion"
                                        name="radioGroup"
                                        value={ReportType.UserExperience}
                                        checked={this.state.type === ReportType.UserExperience}
                                        onChange={this.handleTypeChange}
                                    />
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </Grid.Row>
                </Grid>
                {bugDisplayJSX}
            </Container>
        );
    }
}
