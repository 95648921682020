import * as React from "react";
import {
    Card,
    Image,
    Icon,
    Form,
    Dropdown,
    Input,
    Button,
    Message,
    List,
    ListItem,
} from "semantic-ui-react";
import { inject, observer } from "mobx-react";

import { ProvidedAppStore } from "../../store/AppStore";
import { User, departments } from "../../models/User";
import { ConfirmationPopUp } from "../helpers/ConfirmationPopUp";

interface State {
    name: string;
    department: string;
    annotationTime?: any;
    verificationTime?: any;
    sessionTime?: any;
    currentUser?: any;
}

interface ProfileProps {
    user: User | null;
    onSubmit: (user: User) => void;
    canChangeUserType: boolean;
    withDeactivateUser?: boolean;
}

type Props = ProfileProps & ProvidedAppStore;

function formatWorkedTime(workedTime: any) {
    if (workedTime === undefined) return "";

    const timeParts = [];

    // expandable in the future (days, seconds, etc)
    if (workedTime.hours) {
        timeParts.push(`${workedTime.hours} hour${workedTime.hours > 1 ? "s" : ""}`);
    }

    if (workedTime.minutes) {
        timeParts.push(`${workedTime.minutes} minute${workedTime.minutes > 1 ? "s" : ""}`);
    }

    return timeParts.length > 0 ? timeParts.join(", ") : "0 minutes";
}

@inject("store")
@observer
export class Profile extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        if (props.user) {
            this.state = {
                name: props.user.name,
                department: props.user.department,
            };
        } else {
            this.state = {
                name: "",
                department: "",
            };
        }
    }

    async componentDidUpdate(prevProps: Props) {
        if (this.props === prevProps) {
            return;
        }

        if (this.props.user) {
            this.setState({
                name: this.props.user.name,
                department: this.props.user.department,
            });
        } else {
            this.setState({
                name: "",
                department: "",
            });
        }
        await this.fetchMetrics();
    }

    onChange(event: any, data: any) {
        type NewState = Pick<State, keyof State>;

        const { name, value } = data;
        const newState = { [name]: value } as NewState;

        this.setState(newState);
    }

    async onSubmit() {
        const { user, onSubmit } = this.props;
        const { name, department } = this.state;

        if (user == null) {
            return;
        }

        user.name = name;
        user.department = department;
        onSubmit(user);
    }

    async componentDidMount() {
        await this.fetchMetrics();
        const currentUser = await this.props.store?.hwkflowClient.fetchCurrentUser();
        if (currentUser) {
            this.setState({ currentUser: currentUser.data });
        }
    }

    async fetchMetrics() {
        const { user } = this.props;
        if (!user) return;

        // create last month dates
        const now = new Date();
        const startCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        const endCurrentMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

        const response = await this.props.store?.hwkflowClient.fetchUserWorkedHours(
            startCurrentMonth,
            endCurrentMonth
        );

        // Access the data from the response
        if (response) {
            const metrics = response.data; // Now you can access the data
            if (metrics) {
                this.setState({
                    annotationTime: metrics.totalAnnotationTime,
                    verificationTime: metrics.totalVerificationTime,
                    sessionTime: metrics.totalSessionTime,
                });
            }
        }
    }

    confirmUserDeactivation = async () => {
        const { deactivateUser } = this.props.store!.adminStore;
        const { user } = this.props;
        if (user?.id) {
            await deactivateUser(user.id);
        }
    };

    render() {
        const { user, withDeactivateUser } = this.props;

        if (user == null) {
            return (
                <Message icon>
                    <Icon name="info" />
                    <Message.Content>
                        <Message.Header>No User Selected</Message.Header>
                        Please select a user.
                    </Message.Content>
                </Message>
            );
        }

        const userJSX = (
            <>
                <div>User roles:</div>
                <ul>
                    {user.roles?.length ? (
                        user.roles.map((item) => {
                            return (
                                <li key={`${user.id}_${item}`}>
                                    {item.charAt(0)?.toUpperCase() + item.substring(1)}
                                </li>
                            );
                        })
                    ) : (
                        <li>Frozen Access</li>
                    )}
                </ul>
            </>
        );

        const deactivateJSX = (
            <Button
                fluid
                color={"red"}
                basic
                disabled={this.props.store?.userAuthStore?.user?.id === user.id}
            >
                Deactivate
            </Button>
        );

        const confirmJSX = (
            <ConfirmationPopUp
                trigger={deactivateJSX}
                description={`This will deactivate user ${this.props.user?.username || ""}`}
                onYes={() => this.confirmUserDeactivation()}
                onNo={() => {}}
            />
        );

        const userStatisticsJSX = (
            <List>
                <ListItem>Annotation Time: {formatWorkedTime(this.state.annotationTime)}</ListItem>
                <ListItem>
                    Verification Time: {formatWorkedTime(this.state.verificationTime)}
                </ListItem>
                <ListItem>Session Time: {formatWorkedTime(this.state.sessionTime)}</ListItem>
            </List>
        );

        const departmentOptions = departments.map((d) => ({
            text: d,
            value: d,
        }));
        return (
            <Card className="simple-center">
                <Image src={user.profilePicture} wrapped ui={false} />
                <Card.Content>
                    <Card.Header>{user.name}</Card.Header>
                    <Card.Meta>{user.email}</Card.Meta>
                    <Card.Description>
                        <Form>
                            <Form.Field>
                                <label>Name</label>
                                <Input
                                    name="name"
                                    fluid={true}
                                    error={!this.state.name}
                                    placeholder="Enter your name"
                                    value={this.state.name}
                                    onChange={this.onChange}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Department</label>
                                <Dropdown
                                    name="department"
                                    selection={true}
                                    fluid={true}
                                    error={!this.state.department}
                                    placeholder="Select your department"
                                    options={departmentOptions}
                                    value={this.state.department}
                                    onChange={this.onChange}
                                />
                            </Form.Field>
                        </Form>
                    </Card.Description>
                </Card.Content>
                <Card.Content extra={true}>{userJSX}</Card.Content>
                {user.id === this.state.currentUser?.id ? (
                    <Card.Content extra={true}>{userStatisticsJSX}</Card.Content>
                ) : null}

                <Card.Content extra={true} className="button-row">
                    <ConfirmationPopUp
                        trigger={
                            <Button fluid color={"green"} basic>
                                Update
                            </Button>
                        }
                        description={"This will update the name and/or type of account"}
                        onYes={() => {
                            this.onSubmit();
                        }}
                        onNo={() => {}}
                    />
                    {withDeactivateUser && confirmJSX}
                </Card.Content>
            </Card>
        );
    }
}
