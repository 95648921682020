import React from "react";

import { Card, Image, Button, Table, Header } from "semantic-ui-react";
import { Annotation } from "../../models/Annotation";
import { ModelType } from "../../models/ModelType";

import "../annotation/Annotations.css";

import ObjectDetectionGuide from "../../guides/Hwkflow - Object Detection Guidelines.pdf";
import QuadrilateralDetectionGuide from "../../guides/Hwkflow - Quadrilateral Detection Guide.pdf";
import LineDetectionGuide from "../../guides/Hwkflow - Line Detection Guide.pdf";
import HumanKeypointDetectionGuide from "../../guides/Hwkflow - Human Keypoints Guidelines.pdf";
import ClassificationGuide from "../../guides/Hwkflow - Jersey Numbers Guidelines.pdf";
import BasketballBallKeypointsGuide from "../../guides/Hwkflow - Basketball Ball Keypoints Guide.pdf";
import { ImageState } from "../../models/Image";
import { ConfirmationPopUp } from "./ConfirmationPopUp";

interface Props {
    ann: Annotation;
    answer: any;
    tall?: boolean;
    disabled?: boolean;
    approveButton?: boolean;
    onSubmit: (
        discard: boolean,
        answer: any,
        imageState: ImageState,
        isAnnotationUpdate: boolean,
        isCrossValidation: boolean
    ) => void;
    onVerify: (approved: boolean, comment: string, discarded: boolean) => void;
}

interface State {
    comment: string;
    disabled?: boolean;
    approveButton?: boolean;
}

export class AnnotationInfo extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        let disabled = this.props.disabled || false;
        let approveButton = this.props.approveButton || false;
        this.state = { comment: "", disabled: disabled, approveButton: approveButton };
    }

    componentDidUpdate(previousProps: Props) {
        if (this.props !== previousProps) {
            this.setState({ comment: "", disabled: this.props.disabled });
        }
    }

    render() {
        const { ann, answer } = this.props;
        if (!ann) return <div />;

        const { user } = ann;

        const createdData = new Date(ann.createdAt).toLocaleString();

        const onUpdateAndApproveJSX = (
            <Button
                basic
                disabled={this.state.disabled || !this.state.approveButton}
                onClick={() => this.props.onSubmit(false, answer, ImageState.Verified, true, false)}
            >
                Update and Approve
            </Button>
        );

        const onApproveJSX = (
            <Button
                basic
                disabled={!this.state.approveButton}
                color="green"
                onClick={() => this.props.onVerify(true, this.state.comment, ann.discarded)}
            >
                Approve
            </Button>
        );

        const onDeclineJSX = (
            <ConfirmationPopUp
                trigger={
                    <Button basic color="red">
                        Decline
                    </Button>
                }
                description={"This action will send the image back to reannotation"}
                onYes={() => {
                    this.props.onVerify(false, this.state.comment, ann.discarded);
                }}
                onNo={() => {}}
            />
        );

        const onDiscardJSX = (
            <ConfirmationPopUp
                trigger={<Button basic>Discard</Button>}
                description={"This action will discard the image"}
                onYes={() => {
                    this.props.onSubmit(true, answer, ImageState.Unused, false, false);
                }}
                onNo={() => {}}
            />
        );

        if (!user) return <div />;

        let infoJSX = (
            <Table.Cell>
                <Header>{user.name}</Header>
                <Header size="small">{user.department}</Header>
            </Table.Cell>
        );
        let timingsJSX = (
            <Table.Cell>
                <div>
                    <b>Created On:</b> {createdData}
                </div>
                <div>
                    <b>Anotation Took:</b> {ann.completionTime / 1000.0}s
                </div>
            </Table.Cell>
        );
        let commentsJSX = (
            <Table.Cell>
                <div className="comment-data">
                    <b>Comments:</b>
                    <textarea
                        rows={30}
                        className="commentAreaAnnotation"
                        value={this.state.comment}
                        onChange={(e) => this.setState({ comment: e.target.value })}
                    />
                    <p>Give a reason for Declining</p>
                </div>
            </Table.Cell>
        );
        let buttonsJSX = (
            <Table.Cell colSpan="3">
                <div className="ui four buttons">
                    {onApproveJSX}
                    {onUpdateAndApproveJSX}
                    {onDeclineJSX}
                    {onDiscardJSX}
                </div>
            </Table.Cell>
        );

        let tableJSX = (
            <Table>
                <Table.Row>
                    {infoJSX}
                    {timingsJSX}
                    {commentsJSX}
                    {buttonsJSX}
                </Table.Row>
            </Table>
        );
        if (this.props.tall) {
            tableJSX = (
                <Table width={10}>
                    <Table.Row>
                        {infoJSX}
                        {timingsJSX}
                        {commentsJSX}
                    </Table.Row>
                    <Table.Row>{buttonsJSX}</Table.Row>
                </Table>
            );
        }

        return (
            <Card fluid width={20}>
                <Card.Content>
                    <Card.Description>{tableJSX}</Card.Description>
                </Card.Content>
            </Card>
        );
    }
}

export function fnGenerateVerifiedByJSX(annotation: Annotation) {
    const formatDate = (date: Date) => new Date(date).toDateString();

    const userContent = (user: any, timeDate: Date, timeString: string) => (
        <>
            <Card.Content>
                <Image floated="right" size="mini" src={user.profilePicture} />
                <Card.Header>{user.name}</Card.Header>
                <Card.Description>
                    <div>
                        {user.department} <br />
                        <b>{timeString}:</b> {formatDate(timeDate)}
                    </div>
                </Card.Description>
            </Card.Content>
        </>
    );

    return (
        <Card fluid>
            {userContent(annotation.user, annotation.createdAt, "Annotated on")}
            {userContent(annotation.verifier, annotation.verifiedAt, "Verified on")}
        </Card>
    );
}

let guides = new Map<string, any>([
    ["Jersey Numbers", ClassificationGuide],
    ["Jersey Numbers 2d", ClassificationGuide],
    ["BaseballVideo", ObjectDetectionGuide],
    ["CricketVideo", ObjectDetectionGuide],
    ["CricketVideoDataset", ObjectDetectionGuide],
    ["BasketballVideo", ObjectDetectionGuide],
    ["FootballVideo", ObjectDetectionGuide],
    ["iHawkVideo", ObjectDetectionGuide],
    ["NFLVideoWMarker", ObjectDetectionGuide],
    ["RugbyVideo", ObjectDetectionGuide],
    ["TennisVideo", ObjectDetectionGuide],
    ["VolleyballVideo", ObjectDetectionGuide],
    ["IceHockeyVideo", ObjectDetectionGuide],
    ["Baseball Bat", null],
    ["humans", HumanKeypointDetectionGuide],
    ["humans with hockey sticks", HumanKeypointDetectionGuide],
    ["Humans with Tennis Rackets", HumanKeypointDetectionGuide],
    ["Badminton Rackets", null],
    ["Cricket Bat", null],
    ["Cricket Pitch", null],
    ["F1 Keypoints", null],
    ["Hand KeyPoints", null],
    ["NASCAR & SuperCar Keypoints", null],
    ["Tennis Rackets", null],
    ["Metric Learning", null],
    ["FieldSports - Single Frame", ObjectDetectionGuide],
    ["NFL Pitch Digits and Markers", QuadrilateralDetectionGuide],
    ["NFL-field-lines", LineDetectionGuide],
    ["Tennis-court-lines", LineDetectionGuide],
    ["Football-pitch-lines", LineDetectionGuide],
    ["Basketball Ball", BasketballBallKeypointsGuide],
    ["Team Metrics", null],
]);

export function pdfButton(guide: any, message: string) {
    return (
        <div>
            <Button
                fluid
                content={message}
                icon="file alternate outline"
                labelPosition="right"
                floated="right"
                basic
                color="black"
                onClick={() => window.open(guide)}
            />
        </div>
    );
}

export function pdfJSX(modelType: ModelType) {
    let model = modelType.name;
    let pdfJSX = <div />;
    if (!guides.has(model)) {
        console.log(`List of annotation guides needs updating! No entry for ${model}`);
        return pdfJSX;
    }
    let guide = guides.get(model);
    if (guide != null) {
        return pdfButton(guide, "View Annotation Guide");
    }

    return pdfJSX;
}

export function hexToRgba(hex: string, opacity: number) {
    // Remove the hash (#) if it exists
    hex = hex.replace(/^#/, "");

    let r = parseInt(hex.slice(0, 2), 16);
    let g = parseInt(hex.slice(2, 4), 16);
    let b = parseInt(hex.slice(4, 6), 16);

    // Return rgba string
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
